import React from 'react';

export const Security = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.68301 2.46023C6.29783 1.8454 7.13171 1.5 8.00121 1.5C8.8707 1.5 9.70458 1.8454 10.3194 2.46023C10.9342 3.07505 11.2796 3.90893 11.2796 4.77842V7.25117H4.72278V4.77842C4.72278 3.90893 5.06819 3.07505 5.68301 2.46023ZM3.22278 7.25117V4.77842C3.22278 3.51111 3.72622 2.2957 4.62235 1.39957C5.51848 0.50344 6.73389 0 8.00121 0C9.26852 0 10.4839 0.50344 11.3801 1.39957C12.2762 2.2957 12.7796 3.51111 12.7796 4.77842V7.25117H13.6407C14.9449 7.25117 16.0021 8.30839 16.0021 9.61254V15.2523C16.0021 16.5565 14.9449 17.6137 13.6407 17.6137H2.36113C1.05698 17.6137 -0.000244141 16.5565 -0.000244141 15.2523V9.61254C-0.000244141 8.30839 1.05698 7.25117 2.36113 7.25117H3.22278ZM1.49976 9.61254C1.49976 9.13682 1.8854 8.75117 2.36113 8.75117H13.6407C14.1164 8.75117 14.5021 9.13682 14.5021 9.61254V15.2523C14.5021 15.7281 14.1164 16.1137 13.6407 16.1137H2.36113C1.8854 16.1137 1.49976 15.7281 1.49976 15.2523V9.61254Z"
      fill="currentColor"
    />
  </svg>
);
