import React from 'react';

export const EyeCrossed = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.27501 14.0688L4.16876 13.1812C3.19014 12.3026 2.42124 11.2154 1.91876 10C3.18751 6.83125 6.68751 4.375 10 4.375C10.8524 4.38625 11.6972 4.5383 12.5 4.825L13.4688 3.85C12.3704 3.38583 11.1923 3.13959 10 3.125C7.96283 3.20161 5.99275 3.87347 4.33326 5.05755C2.67377 6.24164 1.39761 7.88603 0.662509 9.7875C0.612863 9.92482 0.612863 10.0752 0.662509 10.2125C1.21766 11.6856 2.11278 13.0069 3.27501 14.0688Z"
      fill="currentColor"
    />
    <path
      d="M7.50001 9.83125C7.54347 9.23232 7.80106 8.66905 8.22569 8.24443C8.65031 7.81981 9.21358 7.56221 9.81251 7.51875L10.9438 6.38125C10.3099 6.21434 9.64328 6.21651 9.01048 6.38754C8.37768 6.55857 7.80077 6.89249 7.33726 7.356C6.87374 7.81951 6.53983 8.39642 6.3688 9.02922C6.19777 9.66202 6.1956 10.3286 6.36251 10.9625L7.50001 9.83125Z"
      fill="currentColor"
    />
    <path
      d="M19.3375 9.7875C18.6208 7.92075 17.3738 6.30439 15.75 5.1375L18.75 2.13125L17.8688 1.25L1.25001 17.8687L2.13126 18.75L5.31876 15.5625C6.73993 16.3963 8.35254 16.8484 10 16.875C12.0372 16.7984 14.0073 16.1265 15.6668 14.9424C17.3262 13.7584 18.6024 12.114 19.3375 10.2125C19.3872 10.0752 19.3872 9.92482 19.3375 9.7875ZM12.5 10C12.4974 10.4376 12.3799 10.8668 12.1594 11.2447C11.939 11.6227 11.6231 11.9361 11.2435 12.1538C10.8639 12.3714 10.4338 12.4856 9.99626 12.485C9.55869 12.4843 9.12895 12.3688 8.75001 12.15L12.15 8.75C12.3747 9.12858 12.4954 9.55978 12.5 10ZM10 15.625C8.6888 15.6021 7.40244 15.2634 6.25001 14.6375L7.83751 13.05C8.55959 13.551 9.43472 13.7825 10.3101 13.704C11.1855 13.6256 12.0055 13.2421 12.6269 12.6207C13.2484 11.9992 13.6318 11.1792 13.7103 10.3038C13.7888 9.42846 13.5573 8.55333 13.0563 7.83125L14.85 6.0375C16.2841 7.02186 17.4056 8.39714 18.0813 10C16.8125 13.1687 13.3125 15.625 10 15.625Z"
      fill="currentColor"
    />
  </svg>
);
