import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';

export const label01 = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 16 / 12,
};

export const label02 = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 16 / 12,
};

export const body01 = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 20 / 14,
  letterSpacing: 0,
};

export const body02 = {
  fontSize: 17,
  fontWeight: 400,
  lineHeight: 24 / 17,
};

export const heading01 = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 20 / 14,
};

export const heading02 = {
  fontSize: 17,
  fontWeight: 500,
  lineHeight: 24 / 17,
};

export const heading03 = {
  fontSize: 20,
  fontWeight: 500,
  lineHeight: 28 / 20,
};

export const heading04 = {
  fontSize: 24,
  fontWeight: 500,
  lineHeight: 32 / 24,
};

export const heading05 = {
  fontSize: 28,
  fontWeight: 500,
  lineHeight: 36 / 28,
};

export const baseFont = {
  fontFamily: 'Roboto, -apple-system, sans-serif',
};
