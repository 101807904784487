import React from 'react';

export const ChevronLeft = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10L10 0L11.4 1.4L2.8 10L11.4 18.6L10 20L0 10Z"
      fill="currentColor"
    />
  </svg>
);
