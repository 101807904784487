import {
  backgroundInverse,
  gradient,
  textInverse,
  textSecondary,
} from './colors';

export interface CustomCiamUiTheme {
  backgroundColor: string;
  footerTextColor: string;
  footerLinkColor: string;
  headerBackgroundColor: string;
  buttonColorPrimary: string;
}

export const dsoTheme = {
  backgroundColor: gradient,
  footerTextColor: textInverse,
  footerLinkColor: 'inherit',
  headerBackgroundColor: backgroundInverse,
};

export const resellerTheme = {
  backgroundColor: 'white',
  footerTextColor: textSecondary,
  footerLinkColor: '#473A6D !important',
  headerBackgroundColor: backgroundInverse,
};
