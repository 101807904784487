import clsx from 'clsx';
import * as React from 'react';
import useStyles from './Typography.styles';

// TODO
// strong bolder >> 500
export interface ITypographyProps {
  children: React.ReactNode;
  variant?:
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'heading5'
    | 'body2'
    | 'label1'
    | 'sub';
  tag?: 'div' | 'p' | 'span';
  className?: string;
}

const Typography: React.FC<ITypographyProps> = ({
  variant,
  tag: Component = 'div',
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Component
      className={clsx(classes.root, variant && classes[variant], className)}
    >
      {children}
    </Component>
  );
};

export default Typography;
