export const linkColor = '#209AD6';

export const controlFillInteractive = '#209AD6';

export const borderInteractive = '#209AD6';
export const borderSuccess = '#257F49';
export const borderError = '#CF2E38';
export const borderSubtle = '#00000014';
export const borderSubtle01 = '#00000020';
export const borderFocus = '#009ACE';

export const buttonColorPrimary = '#209AD6';

export const backgroundSubtle00 = '#F2F2F2';
export const backgroundSubtle01 = '#FFFFFF';
export const backgroundSubtle02 = '#F4F4F4';
export const backgroundStatusGreen = '#CBF5D8';
export const backgroundStatusRed = '#FFE6E8';
export const backgroundStatusBlue = '#D4F0FF';
export const backgroundDestructiveHover = '#A21B23';
export const backgroundInverse = '#3B3B3B';

export const textPrimary = '#000000F0';
export const textSecondary = '#000000A1';
export const textSuccess = '#257F49';
export const textError = '#CF2E38';
export const textTertiary = '#00000071';
export const textInverse = '#FFFFFF';
export const textOnColorSecondary = '#FFFFFFC5';
export const textDisabled = '#00000020';
export const textPlaceHolder = '#888888';

export const iconPrimary = '#000000F0';
export const iconSuccess = '#257F49';
export const iconError = '#CF2E38';
export const iconSecondary = '#000000A1';
export const iconLink = '#009ACE';
export const iconInverse = '#FFFFFF';

export const gradient = 'linear-gradient(292.42deg, #004B70 0%, #009ACE 100%)';
