import { createUseStyles } from 'react-jss';
import {
  backgroundSubtle01,
  borderError,
  borderSuccess,
  iconError,
  iconPrimary,
  iconSuccess,
  textPrimary,
} from '../../../theme';

export default createUseStyles(
  {
    root: {
      borderRadius: 4,
      overflow: 'hidden',
      background: backgroundSubtle01,
      position: 'relative',
      paddingLeft: 4,
      boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.125)',

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: 4,
      },
    },

    body: {
      display: 'flex',
      color: textPrimary,
      padding: '12px 16px',
    },

    icon: {
      flexShrink: 0,
      width: 20,
      height: 20,
      marginRight: 8,
      boxSizing: 'border-box',
      padding: 1.5,
    },

    notification: {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    success: {
      '& $icon': {
        color: iconSuccess,
      },

      '&:before': {
        backgroundColor: borderSuccess,
      },
    },

    error: {
      '& $icon': {
        color: iconError,
      },

      '&:before': {
        backgroundColor: borderError,
      },
    },

    close: {
      color: iconPrimary,
      flexShrink: 0,
      marginLeft: 16,
      width: 20,
      padding: 5,
      boxSizing: 'border-box',
      height: 20,
    },
  },
  {
    name: 'Notification',
  },
);
