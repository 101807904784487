import React from 'react';

export const Check = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 13L0 6.24995L1.0605 5.18945L6.75 10.8782L16.9395 0.689453L18 1.74995L6.75 13Z"
      fill="currentColor"
    />
  </svg>
);
