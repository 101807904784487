import React from 'react';

export const ChevronRight = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 10L1.99998 20L0.599976 18.6L9.19998 10L0.599976 1.4L1.99998 0L12 10Z"
      fill="currentColor"
    />
  </svg>
);
