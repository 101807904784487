import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import { createUseStyles } from 'react-jss';
import {
  baseFont,
  body01,
  body02,
  heading01,
  heading02,
  heading03,
  heading04,
  heading05,
  label01,
  textPrimary,
  textSecondary,
} from '../../../theme';

export default createUseStyles(
  {
    root: {
      ...baseFont,
      ...body01,
      color: textPrimary,
      '& strong': {
        fontWeight: '500',
      },
    },

    sub: {
      color: textSecondary,
      '& strong': {
        color: textPrimary,
      },
    },

    label1: {
      ...label01,
      color: textSecondary,
      '& strong': {
        color: textPrimary,
      },
    },

    body2: {
      ...body02,
    },

    heading1: {
      ...heading01,
    },

    heading2: {
      ...heading02,
    },

    heading3: {
      ...heading03,
    },

    heading4: {
      ...heading04,
    },

    heading5: {
      ...heading05,
    },
  },
  {
    name: 'Typography',
  },
);
