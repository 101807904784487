import React from 'react';

export const Logout = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 1.46739C0.75 1.07119 1.07239 0.75 1.47008 0.75H7.59073C7.98842 0.75 8.31081 1.07119 8.31081 1.46739C8.31081 1.8636 7.98842 2.18478 7.59073 2.18478H2.19015V15.8152H7.59073C7.98842 15.8152 8.31081 16.1364 8.31081 16.5326C8.31081 16.9288 7.98842 17.25 7.59073 17.25H1.47008C1.07239 17.25 0.75 16.9288 0.75 16.5326V1.46739ZM12.7804 4.54708L17.25 9L12.7804 13.4529L11.7621 12.4384L14.4932 9.71739H4.35039V8.28261H14.4932L11.7621 5.56162L12.7804 4.54708Z"
      fill="currentColor"
    />
  </svg>
);
