import type { I18n } from '@lingui/core';
import {
  en,
  es,
  fr,
  it,
  de,
  ru,
  pt,
  tr,
  ko,
  ja,
  zh,
  vi,
} from 'make-plural/plurals';
import dict from './dict';

export function initTranslation({
  i18n,
  locale = 'en',
}: {
  i18n: I18n;
  locale?: string;
}): void {
  const localesData = {
    en: { plurals: en },
    es: { plurals: es },
    fr: { plurals: fr },
    it: { plurals: it },
    de: { plurals: de },
    pt: { plurals: pt },
    tr: { plurals: tr },
    ru: { plurals: ru },
    ko: { plurals: ko },
    ja: { plurals: ja },
    zh: { plurals: zh },
    'es-MX': { plurals: es },
    'pt-BR': { plurals: pt },
    vi: { plurals: vi },
  };

  i18n.loadLocaleData(localesData);
  i18n.load(dict);
  i18n.activate(locale);
}
