import clsx from 'clsx';
import * as React from 'react';
import useStyles from './Clickable.styles';

export interface IClickableProps {
  className?: string;
  disabled?: boolean;
  onClick?(): void;
}

const Clickable: React.FC<React.PropsWithChildren<IClickableProps>> = ({
  children,
  className,
  disabled,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        {
          [classes.root]: true,
          [classes.clickable]: onClick,
          [classes.disabled]: disabled,
        },
        className,
      )}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </div>
  );
};

export default Clickable;
