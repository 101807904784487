import React, { useContext } from 'react';

export interface INotificationsContext {
  success(args: { message: React.ReactNode }): void;
  error(args: { message: React.ReactNode }): void;
  setContainer(to: 'top' | undefined): void;
}

export const NotificationsContext =
  React.createContext<INotificationsContext | null>(null);

export const {
  Provider: NotificationsProvider,
  Consumer: NotificationsConsumer,
} = NotificationsContext;

export const useNotifications = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('Please provide NotificationsContext');
  }

  return context;
};
