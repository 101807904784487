import React from 'react';

export const Bang = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="-10 -10 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    enableBackground={0}
  >
    <circle
      cx="50"
      cy="50"
      r="49.5"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="6"
    />
    <path
      d="M49.9082 26V54.8369"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="49.9084" cy="69.7214" r="4.90841" fill="currentColor" />
  </svg>
);
