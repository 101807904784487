import clsx from 'clsx';
import * as React from 'react';
import useStyles from './Icon.styles';

export interface IIconBaseProps {
  size?: number | string | null;
  className?: string;
}

const IconBase: React.FC<React.PropsWithChildren<IIconBaseProps>> = ({
  size = '100%',
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      style={{ width: size || undefined, height: size || undefined }}
    >
      {children}
    </div>
  );
};

export default IconBase;
