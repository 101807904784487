import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    root: {
      '& svg': {
        display: 'block',
      },
    },
  },
  {
    name: 'Icon',
  },
);
