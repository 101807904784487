import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { NotificationsProvider } from '../../../contexts/NotificationsContext';
import { Typography } from '../../atoms';
import { INotificationProps, Notification } from '../../atoms/Notification';
import useStyles from './Notifications.styles';

export interface INotificationsProps {}

interface INotification {
  id: string;
  type: INotificationProps['type'];
  message: React.ReactNode;
}

const Notifications: React.FC<React.PropsWithChildren<INotificationsProps>> = ({
  children,
}) => {
  const classes = useStyles();
  const [notifications, setNotifications] = React.useState<INotification[]>([]);
  const [to, setTo] = React.useState<'top' | undefined>(undefined);

  const createNotification = (notification: INotification) =>
    setNotifications((n) => [...n, notification]);

  const removeNotification = (notification: INotification) => () =>
    setNotifications((n) => n.filter((i) => i !== notification));

  const success = ({ message }: Pick<INotification, 'message'>) =>
    createNotification({ type: 'success', message, id: String(Math.random()) });

  const error = ({ message }: Pick<INotification, 'message'>) =>
    createNotification({ type: 'error', message, id: String(Math.random()) });

  const setContainer = (to: 'top' | undefined) => setTo(to);

  const render = (
    <div className={clsx(classes.root, to === 'top' && classes.toTop)}>
      <div className={classes.sticky}>
        <Typography>
          <AnimatePresence>
            {notifications.map((notification) => (
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={notification.id}
                className={classes.item}
              >
                <Notification
                  onCloseRequested={removeNotification(notification)}
                  type={notification.type}
                >
                  {notification.message}
                </Notification>
              </motion.div>
            ))}
          </AnimatePresence>
        </Typography>
      </div>
    </div>
  );

  return (
    <NotificationsProvider value={{ success, error, setContainer }}>
      {render}
      {children}
    </NotificationsProvider>
  );
};

export default Notifications;
