import { createUseStyles } from 'react-jss';
import { HEADER_HEIGHT } from '../Header/Header.styles';

export default createUseStyles(
  {
    root: {
      zIndex: 1000,
      position: 'absolute',
      top: HEADER_HEIGHT,
      bottom: 0,
      right: 0,
      pointerEvents: 'none',
    },

    toTop: {
      top: 0,
    },

    sticky: {
      position: 'sticky',
      top: 8,
    },

    item: {
      margin: 8,
      pointerEvents: 'all',
    },
  },
  {
    name: 'Notifications',
  },
);
