import React from 'react';

export const ChevronDown = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7.75L0.75 1.5L1.625 0.625L7 6L12.375 0.625L13.25 1.5L7 7.75Z"
      fill="currentColor"
    />
  </svg>
);
