import React from 'react';

export const Phone = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0821 0H4.91786C3.61375 0.000979014 2.36365 0.510309 1.44142 1.41617C0.519439 2.322 0.00102038 3.55039 0 4.83172V28.5016C0.000996467 29.7829 0.519407 31.0113 1.44142 31.9172C2.36365 32.823 3.61368 33.3323 4.91786 33.3333H15.0821C16.3863 33.3324 17.6364 32.823 18.5586 31.9172C19.4806 31.0113 19.999 29.7829 20 28.5016V4.83172C19.999 3.55045 19.4806 2.322 18.5586 1.41617C17.6364 0.51034 16.3863 0.00100251 15.0821 0ZM18.2143 28.502C18.2123 29.3175 17.8817 30.0992 17.2948 30.6759C16.7079 31.2525 15.9122 31.5773 15.0821 31.5793H4.91786C4.08778 31.5773 3.29214 31.2525 2.70523 30.6759C2.11831 30.0992 1.78771 29.3175 1.78573 28.502V4.8321C1.78773 4.01656 2.1183 3.23485 2.70523 2.65822C3.29214 2.08158 4.08784 1.75677 4.91786 1.75483H5.17494C4.92209 2.24213 4.94601 2.82344 5.23772 3.28917C5.52944 3.75494 6.04689 4.03761 6.60338 4.03542H13.3889C13.9454 4.03762 14.4631 3.75493 14.7548 3.28917C15.0465 2.82341 15.0702 2.24211 14.8174 1.75483H15.0744C15.906 1.75483 16.7032 2.07889 17.2919 2.65577C17.8803 3.23266 18.2119 4.01537 18.2138 4.8321L18.2143 28.502Z"
      fill="currentColor"
    />
  </svg>
);
