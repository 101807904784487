import { createUseStyles } from 'react-jss';
import { CustomCiamUiTheme, textInverse } from '../../../theme';

export const HEADER_HEIGHT = 58;

export default createUseStyles(
  {
    root: {
      background: ({ theme }: { theme: CustomCiamUiTheme }) =>
        theme.headerBackgroundColor,
      height: HEADER_HEIGHT,
      padding: [0, 32],
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: textInverse,
      ['@media screen and (max-width: 768px)']: {
        position: 'sticky',
        top: 0,
        flexDirection: 'column',
        height: 'fit-content',
        padding: '10px 0px 20px 0px',
        alignItems: 'start',
        paddingLeft: '1rem',
      },
    },

    logos: {
      flexGrow: 0,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      margin: [16, 0],
      height: 26,
    },

    partnerDisplayName: {
      marginLeft: 15,
      borderLeft: '1px solid',
      paddingLeft: 15,
    },

    profile: {
      height: '100%',
      flexGrow: 0,
      display: 'flex',
      alignItems: 'center',
      width: '95%',
    },
  },
  {
    name: 'Header',
  },
);
