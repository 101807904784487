import axios, { AxiosInstance } from 'axios';

import { IBffEnableEmailData } from '../../pages/api/ciam/mfa/email';
import { IBffEnablePhoneData } from '../../pages/api/ciam/mfa/phone';
import { IBffVerifyMfa } from '../../pages/api/ciam/mfa/verify';
import { IBffChangePasswordData } from '../../server/schemas';
import { extractData } from '../BffClient/extractors';
import { extractNothing } from './extractors';
import { extractError } from './extractors/extractError';
import { extractOob } from './extractors/extractOob';

export class CiamClient {
  private client: AxiosInstance;
  public businessUnit = '';
  constructor() {
    this.client = axios.create({
      baseURL: '/api',
    });
  }

  userinfo = (businessUnit: string) =>
    singleton(() =>
      this.client
        .post('/ciam/userinfo', { businessUnit })
        .then(extractData, extractError),
    );

  mfa = (businessUnit: string) =>
    singleton(() =>
      this.client
        .post('/ciam/mfa', { businessUnit })
        .then(extractData, extractError),
    );
  changePassword = (data: IBffChangePasswordData) =>
    this.client
      .post('/ciam/password/change', { ...data })
      .catch(extractError)
      .then(extractNothing);

  removeMfa = (data: { id: string }) =>
    this.client
      .delete('/ciam/mfa', { data })
      .then(extractNothing, extractError);

  removeAllMfaMethods = (businessUnit: string) =>
    this.client
      .delete('/ciam/mfa/mfas', { data: { businessUnit: businessUnit } })
      .then(extractNothing, extractError);

  enableEmail = (data: IBffEnableEmailData) =>
    this.client.post('/ciam/mfa/email', data).then(extractOob, extractError);

  enablePhone = (data: IBffEnablePhoneData) =>
    this.client.post('/ciam/mfa/phone', data).then(extractOob, extractError);

  verifyMfa = (data: IBffVerifyMfa) =>
    this.client
      .post('/ciam/mfa/verify', data)
      .then(extractNothing, extractError);
}

const singleton = function <Out>(fn: () => Promise<Out>) {
  const ref: { current: null | Promise<Out> } = {
    current: null,
  };

  return () => {
    if (!ref.current) {
      ref.current = fn().then(
        (data) => {
          ref.current = null;
          return data;
        },
        (e) => {
          ref.current = null;
          throw e;
        },
      );
    }

    return ref.current;
  };
};
