import React, { useContext } from 'react';
import { CiamClient } from '../client/CiamClient/CiamClient';

export const ClientContext = React.createContext<CiamClient | null>(null);

export const { Provider: ClientProvider, Consumer: ClientConsumer } =
  ClientContext;

export const useClientContext = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error('Please provide ClientContext');
  }

  return context;
};
