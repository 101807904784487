import React from 'react';

export const Error = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0.25C4.1875 0.25 0.25 4.1875 0.25 9C0.25 13.8125 4.1875 17.75 9 17.75C13.8125 17.75 17.75 13.8125 17.75 9C17.75 4.1875 13.8125 0.25 9 0.25ZM8.3125 4H9.6875V10.875H8.3125V4ZM9 14.625C8.5 14.625 8.0625 14.1875 8.0625 13.6875C8.0625 13.1875 8.5 12.75 9 12.75C9.5 12.75 9.9375 13.1875 9.9375 13.6875C9.9375 14.1875 9.5 14.625 9 14.625Z"
      fill="currentColor"
    />
  </svg>
);
