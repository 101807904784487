import { QueryClient } from '@tanstack/react-query';

export const queryClient = (() =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: true,
        refetchInterval: Infinity,
      },
    },
  }))();
