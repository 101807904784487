import clsx from 'clsx';
import * as React from 'react';
import pauseable, { Timeout } from 'pauseable';
import { Clickable, Icon } from '..';
import useStyles from './Notification.styles';

export interface INotificationProps {
  type: 'success' | 'error';
  children: React.ReactNode;
  onCloseRequested(): void;
}

const icons: Record<INotificationProps['type'], React.ReactNode> = {
  success: <Icon type="checkmark" />,
  error: <Icon type="error" />,
};

const Notification: React.FC<INotificationProps> = ({
  type,
  children,
  onCloseRequested,
}) => {
  const classes = useStyles();
  const timeout = React.useRef<Timeout>();

  React.useEffect(() => {
    timeout.current = pauseable.setTimeout(onCloseRequested, 5000);
    return () => timeout.current?.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnter = () => timeout.current?.pause();
  const handleLeave = () => timeout.current?.resume();

  return (
    <div
      className={clsx(classes.root, classes[type])}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <div className={classes.body}>
        <div className={classes.icon}>{icons[type]}</div>
        <div className={classes.notification}>{children}</div>

        <Clickable className={classes.close} onClick={onCloseRequested}>
          <Icon type="cross" />
        </Clickable>
      </div>
    </div>
  );
};

export default Notification;
