import { Bang } from './Bang';
import { Check } from './Check';
import { Checkmark } from './Checkmark';
import { ChevronDown } from './ChevronDown';
import { ChevronLeft } from './ChevronLeft';
import { ChevronRight } from './ChevronRight';
import { Cross } from './Cross';
import { Delete } from './Delete';
import { Edit } from './Edit';
import { Error } from './Error';
import { Eye } from './Eye';
import { EyeCrossed } from './EyeCrossed';
import { Logout } from './Logout';
import { Mail } from './Mail';
import { Phone } from './Phone';
import { Plus } from './Plus';
import { Security } from './Security';
import { Success } from './Success';

export default {
  bang: Bang,
  check: Check,
  checkmark: Checkmark,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  cross: Cross,
  delete: Delete,
  edit: Edit,
  error: Error,
  eye: Eye,
  eyeCrossed: EyeCrossed,
  logout: Logout,
  mail: Mail,
  phone: Phone,
  plus: Plus,
  security: Security,
  success: Success,
};
