import axios, { AxiosError } from 'axios';

export const extractError = (e: Error | AxiosError) => {
  if (
    axios.isAxiosError(e) &&
    (e.response?.data as { message: string }).message
  ) {
    throw new Error((e.response?.data as { message: string }).message);
  }
  throw e;
};
