import * as React from 'react';
import IconBase, { IIconBaseProps } from './IconBase';
import ICONS from './icons';

export { ICONS };

export interface IIconProps extends IIconBaseProps {
  type: keyof typeof ICONS;
}

const Icon: React.FC<IIconProps> = ({ type, size = '100%', className }) => {
  const Component = ICONS[type];

  return (
    <IconBase {...{ size, className }}>
      <Component />
    </IconBase>
  );
};

export default Icon;
