import { createUseStyles } from 'react-jss';
import { textDisabled } from '../../../theme';

export default createUseStyles(
  {
    root: {
      userSelect: 'none',
      display: 'inline-block',
    },

    clickable: {
      cursor: 'pointer',
      transition: 'opacity .25s',

      '&:hover': {
        opacity: 0.65,
      },

      '&$disabled': {
        color: textDisabled,
      },
    },

    disabled: {
      pointerEvents: 'none',
    },
  },
  {
    name: 'Clickable',
  },
);
