import React from 'react';

export const Edit = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.75 15.25H0.25V16.5H17.75V15.25Z" fill="currentColor" />
    <path
      d="M14.875 4.625C15.375 4.125 15.375 3.375 14.875 2.875L12.625 0.625C12.125 0.125 11.375 0.125 10.875 0.625L1.5 10V14H5.5L14.875 4.625ZM11.75 1.5L14 3.75L12.125 5.625L9.875 3.375L11.75 1.5ZM2.75 12.75V10.5L9 4.25L11.25 6.5L5 12.75H2.75Z"
      fill="currentColor"
    />
  </svg>
);
